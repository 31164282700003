import React from 'react';
import './App.css';

function App() {
    return (
        <div className="app-container">
            <header>
                <h1>Vasilii Kazmin</h1>
                <p>DevOps Engineer</p>
            </header>
            <section className="about">
                <h2>About me</h2>
                <p>
                    Automation engineer with over 5 years of experience in production automation. Currently
                    transitioning to DevOps and
                    Cloud Engineering to align better with my ambitions and interests.
                </p>
            </section>
            <div className="skills-platform">
                <section className="skills">
                    <h2>Skills</h2>
                    <div className="skill-item"><strong>Containerization</strong> (Docker, Kubernetes, Helm, Kustomize)
                    </div>
                    <div className="skill-item"><strong>IaC</strong> (Terraform, Ansible, Pulumi)</div>
                    <div className="skill-item"><strong>Cloud</strong> (AWS)</div>
                    <div className="skill-item"><strong>CI/CD</strong> (Jenkins, GitHub Actions)</div>
                    <div className="skill-item"><strong>GitOps</strong> (ArgoCD)</div>
                    <div className="skill-item"><strong>Monitoring</strong> (Grafana, Prometheus)</div>
                    <div className="skill-item"><strong>Message Broker</strong> (RabbitMQ)</div>
                    <div className="skill-item"><strong>Service mesh</strong> (LinkerD)</div>
                    <div className="skill-item"><strong>Cloud Storage Orchestration</strong> (Ceph)</div>
                </section>

                <div className="platform-scripting">
                    <div className="platform">
                        <h2>Platform</h2>
                        <div className="skill-item">Linux</div>
                    </div>
                    <div className="scripting">
                        <h2>Scripting</h2>
                        <div className="skill-item">Python</div>
                        <div className="skill-item">Ba(sh)</div>
                    </div>
                </div>
            </div>
            <footer>
                <p>
                    <strong>Contact me</strong>: vasyakazmin171@gmail.com<br/><br/>
                    <a href="https://www.linkedin.com/in/kazminv/" target="_blank"
                       rel="noopener noreferrer">LinkedIn<br/><br/></a>
                    <a href="https://github.com/kazminv" target="_blank"
                       rel="noopener noreferrer">GitHub</a>

                </p>
            </footer>

        </div>
    );
}

export default App;
